ul {
  padding: 0px;
  list-style-type: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.fs-14 {
  font-size: 14px;
}

.main-button {
  background-color: rgb(51, 51, 51);
  color: white;
  padding: 7px 25px;
  border: none;
}

.btn:focus-visible,
.btn:first-child:active {
  /* background-color: #333 !important; */
  box-shadow: none;
}

.main-button:hover {
  background-color: rgb(51, 51, 51);
}

.mainbgadmin {
  background-color: #222b42 !important;
}
.bglightgrey {
  background-color: #dadada !important;
}

.error-message {
  color: #d32f2f;
  margin: 5px !important;
  text-align: start !important;
  font-size: 12px !important;
}
table.css-1kc111s.table {
  border: 1px solid rgba(224, 224, 224, 1);
}
.css-1kc111s thead tr th {
  padding-left: 0;
  padding-right: 0;
  color: #7c7a7a;
  font-size: 15px;
  font-weight: 500;
}

#tab-bootstrap {
  background: none !important;
  border: none !important;
}

.main-table {
  border: 1px solid rgba(224, 224, 224, 1);
}
.vendor-table thead tr th {
  padding-left: 0;
  padding-right: 0;
  color: #7c7a7a;
  font-size: 15px;
  font-weight: bold;
}
.vendor-table tbody tr td {
  font-weight: 500;
}
.myTab {
  border: 2px solid transparent;
  transition: 0.5s all;
  border-radius: 10px;
}
.myTab:hover {
  cursor: pointer;
  border-radius: 10px;
}

.vendor-apexchart {
  box-shadow: 0 2px 9px #b0b8d65c, 2px 1px 2px #b0b8d600;
  border: 0;
  border-radius: 8px;
  background-color: #fff;
}

.chart-content .h3-chart {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1px;
}

.user-calender {
  color: #26377a;
}
.admin-calender {
  color: #222b42;
}

#chart-tabing li {
  padding: 10px 0;
}
.chart-tabing {
  background-color: #ff5722;
  color: #fff !important;
}
#chart-tabing .tab-li {
  background-color: #333;
  border-radius: 50px;
  padding: 5px 0 6px 0px;
}
.latest-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
#chart-tabing .tab-li #link {
  padding: 9px 20px 10px;
  border-radius: 50px;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  transition: all 0.5s;
}

#chart-tabing-admin li {
  padding: 10px 0;
}
#chart-tabing-admin .tab-li {
  background-color: #cfd4d94f;
  border-radius: 50px;
  padding: 5px 0 6px 0px;
}
#chart-tabing-admin .tab-li #link {
  padding: 9px 15px;
  border-radius: 50px;
  text-decoration: none;
  color: #222b42;
  font-size: 12px;
  font-weight: 600;
  transition: 0.5s all ease;
}
#chart-tabing-admin .tab-li #link:hover {
  background-color: #222b42;
  border-radius: 50px;
  color: #fff;
}

.main-table table {
  background: #ffffff;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.14);
  padding: 10px 22px;
}
.main-table table th {
  color: #828282;
  font-weight: 500;
}
.main-table table td {
  font-family: "Inter-Medium";
  font-size: 14px;
}
.main-table {
  padding: 11px 24px 12px;
}

.dashboard-content {
  padding-top: 30px !important ;
}

.active-tab {
  border: 2px solid #ff5722;
}
.active-tab:hover {
  border: 2px solid #ff5722;
}

.slot-content h5 {
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.slot-approved {
  border-top: 4px solid #25ab53;
}
.slot-un-approved {
  border-top: 4px solid #de3163;
}
.slot-markbooked {
  border-top: 4px solid #ff5722;
}
.slot-checked-card {
  border-top: 4px solid #ff5722;
}
.slot-box {
  border-radius: 6px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  padding: 12px;
}
.slot-money {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-slots {
  padding-top: 18px;
}
.slot-img img {
  width: 100%;
  margin: -2px 0px 4px;
}
.slot-img.text-end {
  position: absolute;
  right: 0;
  bottom: 0;
}

.content-body.rightside-event {
  background: #f2f6f9;
  padding-bottom: 20px;
}

.deznav {
  width: 15rem;
  padding-bottom: 0;
  height: calc(100% - 66px);
  position: fixed;
  top: 5rem;
  padding-top: 32px;
  z-index: 5;
  background-color: #fff;
  transition: all 0.2s ease;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #fff;
  padding-top: 31px;
}
.nav-header {
  height: 5rem;
  width: 19.563rem;
  display: inline-block;
  text-align: left;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
  transition: all 0.2s ease;
  background: white;
  z-index: 999;
}
.nav-header img {
  width: 265px;
  padding: 4px 44px;
}
.header-dashboard {
  height: 5rem;
  background: white;
  z-index: 99;
  transition: all 0.2s ease 0s;
  position: fixed;
  top: 0px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
}
.content-body {
  margin-left: 15rem;
  z-index: 0;
  transition: all 0.2s ease;
  padding-top: 7rem;
}
li.nav-item.dropdown.header-profile:hover .dropdown-menu {
  display: block;
  margin-top: 0;
  right: 0;
  border-radius: 0;
  border-top: 3px solid #ff5722;
}

.dropdown-menu.dropdown-menu-end .dropdown-item:active {
  background-color: #e9e9e9;
}

.header-content {
  height: 100%;
  padding-left: 2rem;
  padding-right: 1.875rem;
}
.header-left .dashboard_bar {
  font-size: 26px;
  font-weight: 600;
  color: #000;
}

.header-left .dashboard_bar img {
  width: 15%;
}

li.nav-item.dropdown.header-profile img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: relative;
  top: 0;
}
.dropdown-menu.dropdown-menu-end span.ms-2 {
  color: #000;
}
a.ai-icon.actives {
  background: #ff5722;
  color: #fff !important;
  transition: 0.5s all;
}

a.ai-icon.actives:hover {
  background: #ff5722;
}

a.ai-icon:hover {
  background-color: #333;
  color: #fff !important;
}
a.ai-icon:hover img {
  filter: invert(1);
}

a.dropdown-item.ai-icon:hover {
  background-color: #e9e9e9;
}

ul#menu li a {
  text-decoration: none;
  padding: 11px 0px 10px 0px;
  margin: 0 20px 10px 15px;
  color: #000;
  display: block;
  font-size: 16px;
  border-radius: 5px;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  -o-transition: 0.5s all;
}
ul#menu li a span {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}
ul#menu li a img {
  width: 15%;
  margin-left: 16px;
}

a.ai-icon.actives img {
  filter: invert(1);
}

.dashboard {
  background: #f2f6f9;
  height: 100%;
}
.content-body.rightside-event .container-fluid {
  padding-right: 40px;
  padding-left: 40px;
}
.counter-icon {
  width: 60px;
  height: 60px;
  font-size: 34px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  background: #fa774e2b;
}

.counter-icon img {
  width: 100%;
  max-width: 40px;
}

.counter-icon.admin {
  background: rgba(34, 43, 66, 0.1);
}

.details.p-3 span.border.me-2.p-2.m-2.d-flex.align-items-center {
  width: auto;
  border-radius: 20px;
  display: inline-block !important;
  margin: 0 11px 20px 0px !important;
}

.overflow-hidden.card {
  height: 135px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: border-box;
  box-shadow: 0 2px 9px #b0b8d65c, 2px 1px 2px #b0b8d600;
  border-radius: 8px;
  border: 0;
}

.overflow-hidden.card:hover {
  box-shadow: none;
}

.overflow-hidden-card2 {
  height: 150px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: rgb(254 88 88);
  background-clip: border-box;
  box-shadow: 0 2px 9px #b0b8d65c, 2px 1px 2px #b0b8d600;
  border-radius: 8px;
  border: 0;
}

.verify-add-business {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 9px;
}

.verify-add-business button {
  padding: 5px 15px;
  background-color: #333;
  color: white;
  margin-top: 20px;
  font-size: 15px;
  border: none;
  border-radius: 5px;
}
.slots-box h6.vendor-h6.mb-0 {
  display: inline-block;
}

h2.pricess {
  font-size: 20px;
  font-weight: 500;
}
.overflow-hidden.card p.text-muted.mb-0 {
  font-weight: 400;
  color: #000;
}

.overflow-hidden.card.chart {
  padding: 16px;
}

.banner-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner-image {
  height: 370px;
  position: relative;
}

.presonal-detailbox {
  background: white;
  box-shadow: 0 2px 9px #b0b8d64a, 2px 1px 2px #b0b8d600;
}

.presonal-detailbox .top-banner {
  padding-left: 25px;
  padding-right: 25px;
  box-shadow: none;
  background-color: transparent;
}

.top-banner {
  background-color: #fff;
  background-clip: border-box;
  box-shadow: 0 2px 9px #b0b8d64a, 2px 1px 2px #b0b8d600;
  border-radius: 8px;
  margin-bottom: 35px;
}

p.fs-6.profile-color {
  margin-bottom: 0;
  color: #65676b;
  font-size: 16px;
}

.main-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.banner-text {
  padding: 20px 26px;
}
.Turf-name h6 {
  font-size: 24px;
  margin-bottom: 8px;
}
.Turf-name p {
  font-size: 14px;
  line-height: 25px;
  color: #171717;
}

.review-main {
  padding: 20px;
}
.review-text h6 {
  font-size: 18px;
  margin-bottom: 4px;
}
.review-text ul li {
  display: inline-block !important;
  margin-bottom: 0 !important;
}
.review-text ul li i {
  margin-right: 0 !important;
  color: #ffaa05 !important;
  font-size: 14px !important;
}
.review-text ul {
  margin-bottom: 0;
}
.review-box p {
  font-size: 15px;
  line-height: 28px;
}
.review-box {
  border-bottom: 1px solid #ccc;
}
.part.mt-5 p {
  font-size: 16px;
  line-height: 30px;
}
.slots-box h6.custom-heading {
  display: inline-block;
}
.slots-box span {
  float: right;
  color: #ec3900;
  font-weight: 500;
  font-size: 14px;
}

.slots-book2 label.form-check-label {
  margin-left: 9px;
  margin-top: 6px;
  font-size: 16px;
  font-weight: 900;
}

.top-banner.main-box {
  padding: 22px 20px;
  background-color: white;
}

.top-banner.main-box .tournament-detail img {
  width: 100%;
  height: 400px;
}

.custom-h5 {
  display: flex;
  align-items: center;
  font-size: 13px;
}
h5.custom-h5 {
  font-weight: 500;
  font-size: 13px;
  line-height: 11px;
}

.custom-h5 .booked {
  background-color: #de3163;
  width: 11px;
  height: 11px;
  border-radius: 100%;
  display: block;
  margin: 0 3px;
}
.custom-h5 .mark-booked {
  background-color: #ff5722;
  width: 11px;
  height: 11px;
  border-radius: 100%;
  display: block;
  margin: 0 3px;
}
.custom-h5 .success {
  background-color: #25ab53;
  width: 11px;
  height: 11px;
  border-radius: 100%;
  display: block;
  margin: 0 3px;
}
.main-custom p {
  font-weight: 500;
  font-size: 9px;
  color: #939598;
  text-align: end;
}

.slot-tabel {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.slot-tabel input {
  border-bottom: 1px solid #26377a !important;
  color: #0155a5;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 2px;
  border: 0;
  font-weight: 600;
  color: #000;
  background-color: transparent;
}

.slot-selector {
  position: absolute;
  right: 0;
  top: -3px;
  font-size: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.top-banner.main-box button.accordion-button {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #000 !important;
  font-weight: 600;
  font-size: 18px;
}

.Instruction-inner-2 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.amount-td {
  padding: 6px;
  border: 0.5px solid #939598;
  display: flex;
  align-items: center;
}

.amount-td p {
  color: #171717;
  font-family: Dosis-SemiBold;
}

.invoice-number {
  background: #26387a;
  height: 27px;
  width: 27px;
  text-align: center;
  border-radius: 3px;
}

.edit-icon #add-edit-icon {
  text-align: center;
  font-size: 20px;
  color: #0159ab;
}

button.save-button {
  background-color: #333;
  border: 1px solid #333;
  padding: 8px 46px;
  font-size: 16px;
  border-radius: 5px;
  margin: 8px 0;
  color: #fff;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  -o-transition: 0.5s all;
}

a.Publish.close {
  background-color: #d02800;
}

.Single-date h6 {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 600;
}

.Single-date {
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  border-radius: 5px;
  color: #000;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 15%);
  margin: 11px 0px 3px 0px;
}
.Single-date i {
  color: #058b4b;
  font-size: 18px;
}

.quick-booking {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.quick-booking-button {
  background-color: transparent;
  color: #333;
  height: 38px;
  transition: 0.5s;
  box-shadow: none;
  font-family: Oswald-Regular;
  border: 2px solid #333;
}
.quick-booking-button2 {
  background-color: rgb(38, 55, 122);
  color: black;
  height: 35px;
  transition: 0.5s;
  box-shadow: none;
  font-family: Oswald-Regular;
}

.quick-booking-button:hover {
  background-color: #333;
  color: white;
  box-shadow: none;
}

p.quick-booking-para {
  color: #171717;
  font-size: 14px;
  font-family: Dosis-SemiBold;
}

p.quick-booking-amount {
  color: red;
  font-family: Dosis-SemiBold;
  font-size: 12px;
}

form.from-inline2 .form-check {
  display: inline-block;
  margin-right: 25px;
}

.profile-image img {
  width: 100%;
  border-radius: 10px;
}
.profile-image {
  background-clip: border-box;
  box-shadow: 0 2px 9px #b0b8d699, 2px 1px 2px #b0b8d6;
  background-color: #fff;
  padding: 7px;
  border-radius: 10px;
  position: relative;
}
.profile-image i {
  position: absolute;
  right: 14px;
  top: 19px;
  font-size: 22px;
  color: #fff;
  cursor: pointer;
}

.coming-soon img {
  width: 100%;
}
a.custom {
  color: black;
  font-size: 16px;
  margin-right: 10px;
  text-transform: uppercase;
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  li.nav-item.dropdown.header-profile {
    top: 0 !important;
  }
}

li.nav-item.dropdown.header-profile {
  top: 12px;
}

.slots-box {
  position: relative;
}
.slots-box img {
  position: absolute;
  right: -51px;
  top: -38px;
  width: 100%;
  max-width: 24%;
}
.content-body.rightside-event {
  background-position: center;
  background-size: cover;
}
.top-banner.main-box.Profile-box {
  border: 3px dashed #008c51;
  box-shadow: none;
}
.profile-tabs ul#myTab {
  justify-content: inherit;
}

.myicon {
  color: #014d98;
}

/* ----------- */
.bookings input#outlined-basic {
  padding: 7px 0 7px 35px;
  border: 1px solid #015db1;
  border-radius: 3px;
}

.sort-select {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sort-select .content span {
  color: #656464;
  font-weight: 400;
  font-size: 14px;
  padding: 0 10px;
}

.table-tr {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

textarea:focus-visible {
  outline: 0;
}

.main-turf {
  display: flex;
  padding: 15px 12px;
}

.css-ypiqx9-MuiDialogContent-root {
  padding: 3px 25px;
}

.turf-detail {
  background-color: #fff;
  background-clip: border-box;
  box-shadow: 0 2px 9px #b0b8d699, 2px 1px 2px #b0b8d600;
  border-radius: 8px;
  margin-bottom: 35px;
  padding: 30px 30px;
}

#edit-turf {
  background-color: #fff;
  background-clip: border-box;
  box-shadow: 0 2px 9px #b0b8d699, 2px 1px 2px #b0b8d600;
  border-radius: 8px;
  margin-bottom: 35px;
  padding: 5px 0px;
}

.add-tournament input#outlined-basic {
  border-radius: 30px !important;
}

.add-tournament
  fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 30px;
}

.add-tournament .css-10vjoz-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12px 16px;
}

.add-tournament div#demo-simple-select {
  border-radius: 30px;
  padding: 12px 15px;
}

button.add-turf-button.add-tournamentbtn.btn.btn-primary.btn.btn-primary {
  width: 100% !important;
  border-radius: 30px;
}

.ball-type {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 30px;
  padding: 1.5px 14px;
}

.turf-details {
  padding: 10px 14px 1px;
}
.turf-details h2 {
  font-size: 22px;
}

.turf-icon img {
  width: 100%;
  max-width: 34px;
}
.turf-icon {
  position: absolute;
  right: 0;
  top: 11px;
}
.turf-icon ul li {
  display: inline-block;
  background-color: #fff;
  margin: 0 5px;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  padding: 3px;
  box-shadow: 0 2px 9px rgb(0 0 0 / 15%), 0 1px 2px rgb(0 0 0 / 15%);
  cursor: pointer;
  line-height: 20px;
  text-align: center;
}

.slot-lable {
  font-size: 16px;
  font-weight: 500;
  padding: 0px 3px 4px 9px;
  color: #000000;
}
.edit-total-slot {
  position: relative;
}
.slots-arrow {
  position: absolute;
  top: 7px;
  right: 9px;
  cursor: pointer;
}

.slots-edit-radio {
  margin: 14px 0 24px;
  display: flex;
  align-items: center;
}
.slots-edit-radio .edit-slot-label {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}

.slots-icon {
  color: #0159ab;
}
.slots-box .slot-buton {
  background: none;
  border: none;
  padding: 0;
}

.main-contact {
  background: #ffffff;
  padding: 18px;
  border-radius: 5px;
}
.contact-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contact-button button {
  background: #26377a;
  border-radius: 5px;
  color: white;
  padding: 4px 26px;
}
.contact-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-contact {
  position: relative;
  margin-right: 16px;
}
.search-contact #outlined-basic {
  padding: 7px 30px 8px 35px;
  border: 1px solid #e0e1e200;
  border-radius: 3px;
}
.search-contact svg {
  position: absolute;
  top: 11px;
  left: 14px;
  color: #0000004d;
}

.contact-content {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}
.contact-content .main {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-detail h5 {
  font-size: 15px;
  font-weight: 700;
}
.contact-detail p {
  font-size: 14px;
  font-weight: 500;
  color: #959697;
}
.contact-checkbox input {
  width: 18px;
  height: 18px;
}
.date span {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #939598;
}

.search-icon #search {
  color: #b7b0b0eb;
}

#turf-add-btn {
  width: 80px;
  background: transparent !important;
  border: 1px solid black !important;
  border-radius: 30px !important;
  color: black;
  margin-left: 20px;
  font-weight: 600 !important;
}

.turf-logo-main .logo {
  border: 3px solid #0062b9;
  border-radius: 100%;
  width: 175px;
  height: 175px;
  text-align: center;
  padding: 36px 0;
}
.turf-logo-main .logo #update-img {
  width: 88px;
  height: 88px;
  color: #0062b9;
}

#spot-logo {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  margin-right: 7px;
  margin-bottom: 1px;
}

.add-turf-bg {
  background-color: #fff;
  width: 90%;
  margin: 0 auto;
}

.amenity-main .amenity-checkbox {
  padding: 12px 13px;
}
.amenity-main .amenity-checkbox input {
  width: 32px;
  height: 17px;
}
.amenity-main .amenity-box {
  background-color: #fff;
  -webkit-box-shadow: 7px 13px 27px -10px rgba(168, 168, 168, 1);
  -moz-box-shadow: 7px 13px 27px -10px rgba(168, 168, 168, 1);
  box-shadow: 7px 13px 27px -10px rgba(168, 168, 168, 1);
}

.turf-heading h5 {
  font-size: 16px;
  color: #000000e3;
}

.save-turf {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

form.add-turf-select input#outlined-address {
  padding: 4px 8px;
  font-size: 13px;
}

.turf-heading h6 {
  font-size: 13px;
}

#edit-turf-btn {
  border-radius: 50px;
  font-size: 15px;
}
#button-edit-turf {
  border-radius: 50px;
  padding: 6px 72px;
}

.flex-btn {
  display: flex;
  text-align: center;
}

.kyc-detail img {
  width: 100%;
  height: 160px;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
}

.kyc-detail {
  width: 60%;
}

.kyc-detail button {
  width: 49%;
}

.team-card {
  padding: 30px 15px 5px 15px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: white;
  margin-bottom: 40px;
}

span.captain-spain {
  width: 20px;
  text-align: center;
  height: 20px;
  font-weight: bold;
  font-size: 14px;
  background: #0c1738;
  color: white;
  font-family: inherit;
  line-height: 19px;
  border-radius: 30px;
}

.add-team {
  padding: 40px 0;
  font-size: 20px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  justify-content: center;
  background: white;
  border: 1px dashed #0c1738;
  color: #0c1738;
  align-items: center;
}

.add-players-card {
  background: #eff0f5;
  border-radius: 5px;
  padding: 20px 0px 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.add-players-card.white {
  background: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.13);
}

table.mui-table.stats.css-1kc111s.table thead tr th {
  padding-left: 0;
  padding-right: 0;
  color: #ffffff;
  font-size: 15px;
}

table.mui-table.stats.css-1kc111s.table.css-1kc111s tbody tr td {
  padding-left: 0;
  text-transform: capitalize;
}

.subtitle-text .MuiTabs-scroller.MuiTabs-fixed.css-jpln7h-MuiTabs-scroller {
  margin-left: 0 !important;
}

.subtitle-text .MuiTabs-root.css-1ujnqem-MuiTabs-root {
  background-color: transparent !important;
}

.batter-num {
  color: rgb(255, 255, 255);
  background-color: rgb(34, 43, 66);
  width: 23px;
  margin-bottom: 20px;
  height: 25px;
  position: absolute;
  right: 9px;
  top: 9px;
}

.match-type-card {
  width: 120px;
  height: 120px;
  text-align: center;
  background: #222b42;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.team-cards {
  background: #ffffff;
  border: 2px solid #ff5722;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  padding: 15px;
  margin-bottom: 50px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.match-card {
  padding: 23px 12px 9px;
  border-radius: 10px;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
  background: white;
  margin-bottom: 50px;
}

.my-card {
  border-radius: 10px;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
  background: white;
}

.tableButtonHover:hover {
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
}
.team-logo {
  width: 70px;
  height: 70px;
  background-color: black;
  border-radius: 50%;
}

.toss-box {
  background-color: #fff;
  color: rgba(52, 49, 76, 1);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) !important;
  height: 145px;
  padding: 18px 0px;
  border: 3px solid transparent;
  transition: 0.5s all ease;
}

.toss-box:hover {
  border: 3px solid #222b42;
}

.borderhover {
  transition: 0.5s all ease;
}
.borderhover div img {
  transition: 0.5s all ease;
  transform: scale(1);
}
.borderhover:hover {
  border: 2px solid #222b42 !important;
}

.borderhover:hover div img {
  transform: scale(1.2);
}

.personal-add .css-10vjoz-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12px 16px;
}

.personal-add #personal-btn button {
  border-radius: 50px;
  padding: 7px 90px;
}

.bussiness-add #personal-btn button {
  border-radius: 50px;
  padding: 7px 90px;
}

.multiple-slots-btn {
  padding: 7px;
  background-color: #333;
  border: none;
}

.multiple-slots-btn:hover {
  background-color: #333;
}

.multiple-slot-booking {
  color: #ff5722;
  border: 0;
  background: none;
}
.multiple-slot-confirmed {
  color: #a00d00;
  border: 0;
  background: none;
}

.next-btn button {
  background: #333;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08), 0px 2px 2px rgba(0, 0, 0, 0.08),
    0px 0px 1px rgba(0, 0, 0, 0.08);
  border-radius: 5px !important;
  color: #fff;
  padding: 10px 30px;
  border: 0;
  font-size: 14px;
  font-family: Oswald-Regular;
}

.field-content {
  position: relative;
}

.approve-pra {
  font-size: 13px;
  color: #0000009e;
}

.upload-image {
  position: absolute;
  right: 0px;
  top: 5px;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  max-width: 130px;
  margin: 0 auto;
  border-radius: 5px;
}
.upload-image img {
  width: 100%;
  height: 130px;
  object-fit: cover;
}

.upload-image2 {
  position: absolute;
  right: 0px;
  top: 5px;
  left: 0;
  text-align: center;
  height: 100%;
  max-width: 231px;
  margin: 0 auto;
  border-radius: 5px;
}
.upload-image2 img {
  width: 100%;
  height: 107px;
}

.add-new {
  display: flex;
  flex-direction: column;
  border: 2px solid #828282;
  border-radius: 5px;
  justify-content: space-between;
  height: 100%;
  padding: 30px;
}

label.vendor-input-label {
  color: #171717;
  font-size: 14px;
  font-family: Dosis-SemiBold;
  text-align: left;
}

.vendor-heading {
  font-size: 26px;
  font-weight: bold;
}

.vendor-text {
  font-size: 14px;
}

.vendor-h4 {
  font-size: 18px;
}

.vendor-h6 {
  font-size: 15px;
}

.vendor-p {
  font-size: 15px;
  color: #000;
}

.login-content input#outlined-start-adornment {
  border-left: 1px solid #828282;
  padding-left: 12px;
}

button.kyc-button {
  background: #333;
  color: #fff;
  border: 0;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
}

button.kyc-button:hover {
  background: #333;
}

.table-td {
  font-size: 14px;
  font-family: Dosis-SemiBold;
  padding-left: 20px !important;
}
.th-table {
  font-size: 13px;
  font-family: Dosis-SemiBold;
  font-size: 16px;
  padding-left: 20px !important;
}

.save-btn {
  background-color: #333;
  color: white;
  width: 24% !important;
  font-size: 16px;
  font-family: Oswald-Regular;
}

.btn:hover {
  color: #fff;
}

.bank_input .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 5px;
}

.whatsapp-support a {
  font-size: 15px;
  text-decoration: none;
  color: #171717;
  cursor: pointer;
}
.profile-box {
  box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  padding: 34px;
}
/* footer {
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  background-color: #fff !important;
  padding: 10px 0 0;
} */
.details .css-1bw0nnu-MuiStep-root {
  padding-left: 8px;
  padding-right: 0;
}
.email-support span {
  display: block;
  font-size: 16px;
  color: #828282;
  padding: 12px 0;
}
.email-support {
  border: 1px solid #26377a;
  padding: 61px 0;
  margin: 40px 6px 50px;
  border-radius: 5px;
}
.email-support img {
  width: 100%;
  max-width: 20%;
}
.top-banner.main-box.Contact-supportbox {
  height: 85vh;
  margin-bottom: 0;
}

.edit-button-ground {
  position: absolute;
  right: 25px;
  bottom: 20px;
  width: 45px;
  height: 44px;
  background-color: #333333ad;
  padding: 9px 13px;
  border-radius: 38px;
}

button.ground-button {
  background: #333;
  color: #fff;
  border: 0;
  font-size: 14px;
  padding: 7px 10px;
  border-radius: 5px;
}

.businees-time p {
  margin-bottom: 0;
  font-size: 14px;
  padding-left: 2px;
}
.businees-time {
  position: absolute;
  left: 160px;
  top: 77px;
  width: 174px;
  /* text-align: right; */
}
.turf-review {
  border: 0.25px solid rgb(0 0 0 / 41%);
  background: #fff;
  padding: 18px 18px 0 18px;
  position: relative;
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 140px;
}

.review-bottom-inner {
  border: 0.25px solid rgb(0 0 0 / 41%);
  padding: 0px 20px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 25px;
}

.main-box-review {
  display: flex;
  gap: 14px;
}
.review-text span {
  position: absolute;
  right: 15px;
  top: 14px;
  font-size: 14px;
  color: #828282;
}
.review-image img {
  width: 45px;
}

.bank_input .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 5px;
}

.bottm-bar p {
  font-size: 14px;
  margin-bottom: 5px;
}
.turf-review .review-text h6 {
  font-size: 16px;
  margin-bottom: 4px;
}

.user-heading tbody.MuiTableBody-root.css-apqrd9-MuiTableBody-root td {
  padding-left: 20px;
}

/* / -----------------------------------Vendor dashboar latest ----------------- / */

.user-card {
  border: 1px solid #000000;
  border-radius: 6px;
  padding: 16px 12px;
  position: relative;
}
.user-card p {
  font-size: 13px;
  color: #828282;
}
.user-card .booking-increase span {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 100%;
}
.img-position {
  position: absolute;
  top: 0;
  right: 0;
}
.dashboard-turf {
  font-size: 14px;
  color: #171717;
  margin: 0 24px 0 0;
  font-family: Dosis-Medium;
  padding: 3px 0px;
  text-decoration: none;
  text-transform: uppercase;
}

.bottem-active {
  background: #333;
  color: white;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 11px;
  text-transform: uppercase;
}

.main-chart .apexcharts-canvas {
  margin: 0 auto !important;
}
.remain-main {
  padding: 13px;
  box-shadow: 0 2px 9px #b0b8d65c, 2px 1px 2px #b0b8d600;
  border-radius: 8px;
  background-color: #fff;
}
.remain-slots {
  padding: 10px;
  background: #25ab53;
  border-radius: 5px;
  width: 100%;
}
.icon_up {
  height: 20px;
  width: 20px;
  background-color: #0062b9;
}
.reject_btn {
  font-size: 25px;
  cursor: pointer;
  margin-top: -12px;
  margin-right: -20px;
}
.vendor_card_heading {
  font-size: 16px;
  color: #26377a;
}
.vendor_card_p {
  font-size: 14px;
  color: #26377a;
}
.booking_modal_close
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  padding-bottom: 5px;
}

.location_input .css-1kkal6p-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 4px;
}

.arrow-right {
  position: absolute;
  top: 37%;
  right: 10px;
}
.arrow-position {
  position: relative;
}

.button.Quick-booking-btn.btn.btn-primary {
  width: 80px;
  background: transparent !important;
  border: 1px solid black !important;
  border-radius: 5px !important;
  color: black;
  font-weight: 600 !important;
  margin-bottom: 10px;
}
.add-turf-select
  form#add-turf
  .css-1xbpyzz-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 16.5px 14px;
}
.vendor-card-p {
  font-size: 13px;
}
.vendor-card-heading {
  font-size: 16px;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 42px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #fff;
}

.sidenav a {
  padding: 15px 0px 15px 10px;
  text-decoration: none;
  display: block;
  transition: 0.3s;
  color: #000;
  display: block;
  font-size: 14px;
}
.sidenav a:hover {
  color: #000;
}
div#mySidenav li img {
  width: 12%;
  margin-left: 6px;
  padding-right: 0px;
  position: relative;
}
.sidenav .closebtn {
  position: absolute;
  top: -7px;
  right: 12px;
  font-size: 33px;
  margin-left: 0;
  padding: 6px 0;
}
.open {
  width: 270px !important;
}
a.vendor-h6 {
  color: #171717;
  text-decoration: none;
}

ul.social-icon-vendor-sidebar li {
  background-color: #ff5722;
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  text-align: center;
  line-height: 37px;
  color: white;
  font-size: 19px;
  margin: 0 4px;
}

ul.social-icon-vendor-sidebar li a {
  color: white;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

span.menu-toogle {
  display: none;
}

@media only screen and (max-width: 767px) {
  span.menu-toogle {
    display: block;
  }

  .team-list {
    flex-direction: column;
  }
  .Ball-select {
    text-align: center;
  }
  .deznav {
    display: none;
  }
  .dashboard_bar {
    display: none;
  }
  img.mobile-logss {
    width: 70%;
  }
  div#mySidenav ul {
    margin-top: -35px;
  }
  span.menu-toogle {
    font-size: 18px !important;
    font-weight: 500;
  }
  .content-body {
    margin-left: 0;
  }
  .content-body.rightside-event .container-fluid {
    padding-right: 20px;
    padding-left: 20px;
  }

  .content-body.rightside-event.main-dash .col-3 {
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: 15px;
  }

  .dashboard-content.mt-2 .col-8,
  .dashboard-content.mt-2 .col-4 {
    width: 100%;
    margin-bottom: 28px;
  }
  .header-dashboard {
    height: 5rem;
  }
  .user-card p {
    font-size: 12px;
  }
  .charts2 {
    display: initial !important;
  }
  .apexcharts-canvas {
    margin: 0 auto;
  }
  .occupancy-bar {
    margin-bottom: 20px;
    padding-top: 20px;
  }
  .charts2 h6.text-center {
    margin-top: -35px;
  }
  .review-bar {
    padding-bottom: 20px;
  }
  .view-bar {
    margin-bottom: 20px;
  }
  .header-content {
    padding-left: 1rem;
    padding-right: 0rem;
  }

  .mt-4.ms-3.users {
    margin-top: 0 !important;
    padding-top: 10px;
  }
  ul#chart-tabing {
    padding: 0 !important;
    margin: 18px 12px 3px !important;
  }
  .top-banner.main-box {
    padding: 0px 10px;
  }
  span.w-100.fs-4.d-flex.justify-content-center.align-items-center {
    display: none !important;
  }
  .slots-box {
    padding-top: 10px;
  }
  .vendor-p {
    font-size: 14px;
  }
  .text-center.mergebutton {
    padding-bottom: 24px;
  }
  .total-slots.d-flex.align-items-center {
    display: initial !important;
  }
  .main-slots .col-3 {
    flex: 0 0 auto;
    width: 50%;
  }
  .slot-box {
    padding: 2px 0px 6px;
  }
  .banner-image {
    height: 160px;
  }
  .logo_img img {
    width: 100px !important;
    height: 100px !important;
    border: 1px solid white;
  }
  .logo_img span.pedit {
    left: 77px;
  }
  .banner-text {
    padding: 10px 10px;
  }
  .businees-time {
    display: none;
  }
  .ground-tab-2 .col-6 {
    flex: 0 0 auto;
    width: 100%;
  }
  .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
    right: 0;
  }
  .top-banner.main-box.Contact-supportbox {
    height: auto;
    margin-bottom: 0;
    padding-top: 16px;
  }

  .holiday-box .d-flex.justify-content-between.mb-4.mt-3.bookings {
    display: inherit !important;
  }
  .holiday-box .sort-select .content span {
    font-size: 12px;
    padding: 8px 0;
    display: block;
  }
  .add-holiday.next-btn.ms-3 {
    margin-left: 0;
    margin-top: 20px;
  }
  .sort-select {
    display: block;
    margin-bottom: 14px;
  }
  .sort-select .MuiFormControl-root.css-1nrlq1o-MuiFormControl-root {
    width: 100% !important;
  }
  .top-banner.main-box.holiday-box {
    padding-top: 2px;
  }
  .top-banner.main-box.holiday-box
    .sort-select
    .MuiFormControl-root.css-1nrlq1o-MuiFormControl-root {
    width: 200px !important;
  }
  .top-banner.main-box.Notification-box .d-flex.justify-content-between {
    display: inherit !important;
    margin-bottom: 30px;
  }
  .top-banner.main-box.Notification-box {
    padding-top: 20px;
  }
  .Notification-box .main-table {
    padding: 0;
  }
  form#add-turf button.p-2.fw-bold.btn.btn-contained {
    width: 100% !important;
  }
  .dashboard-content .col-6 {
    flex: 0 0 auto;
    width: 100%;
  }
  .Occupancy-box .d-flex.justify-content-between.mb-2.mt-3.bookings {
    display: inherit !important;
  }
  .Occupancy-box {
    padding-top: 4px;
  }
  .Booking-calender.d-flex {
    margin-top: 16px;
  }
  .Occupancy-box .main-custom.d-flex.align-items-center {
    display: inherit !important;
  }
  .vendor-apexchart.Bookings-box
    .d-flex.justify-content-between.align-items-center {
    display: inherit !important;
  }
  .vendor-apexchart.Bookings-box h4.h4-chart.vendor-h4 {
    padding-top: 12px;
  }
  .Occupancy-box {
    padding: 1px 15px 23px !important;
    height: auto !important;
  }
  .css-1htgh2s-MuiTableCell-root {
    font-size: 10px;
  }
  .top-banner.main-box.Extend-box .booking-tab.col-1 {
    width: 50%;
  }
  .top-banner.main-box.Extend-box {
    padding: 15px 10px;
  }
  .top-banner.main-box.Amend-box
    .mb-3.d-flex.justify-content-between.align-item-center {
    align-items: baseline;
  }
  .top-banner.main-box.Amend-box {
    padding: 0px 10px 22px;
  }
  .top-banner.main-box.Amend-box .main-table {
    padding: 0 0 0;
  }
  .main-table table th {
    font-size: 16px;
  }
  .top-banner.main-box.Booking-List-box {
    padding-bottom: 10px;
  }
  .turf-list-box .d-flex.justify-content-between {
    display: initial !important;
  }
  .turf-list-box ul#myTab .nav-link {
    padding: 8px 15px !important;
    margin: 0 0px;
    font-size: 12px;
  }
  .turf-list-box li.nav-item.mb-3 {
    margin-bottom: 6px !important;
  }
  .turf-list-box .ground-tab {
    margin-bottom: 24px;
  }
  .css-153eyq5-MuiModal-root-MuiDialog-root .MuiDialogContent-root {
    padding: 0;
  }
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthXl.MuiDialog-paperFullWidth.css-1b2o62t-MuiPaper-root-MuiDialog-paper {
    padding: 12px;
  }
  ul#myTab .nav-link {
    padding: 6px 8px !important;
  }
  section.turf-detail3 .add-turf-select {
    padding: 10px 0;
  }
  .top-banner {
    height: auto !important;
  }
  .presonal-detailbox .css-13xfq8m-MuiTabPanel-root {
    padding: 24px 0;
  }
  .dashboard-content.mt-2 .main-table {
    padding: 0;
  }
  .content-body.rightside-event.turf-list-box .top-banner.main-box {
    padding-top: 12px;
  }
}

.main {
  margin-bottom: 10px;
  padding-bottom: 6px;
}

#customized-dialog-title {
  font-family: Oswald-SemiBold;
}

.country-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.country-list img {
  width: 15%;
}

.list-arrow {
  width: 40px;
  height: 40px;
  background-color: #f5f5f5;
  border-radius: 50%;
  padding: 6px 12px;
}

.list-arrow svg {
  color: #ff4910;
}
