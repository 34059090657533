@font-face {
  font-family: Montserrat-Regular;
  src: url(../fonts/Montserrat-Regular.ttf);
}
@font-face {
  font-family: Montserrat-Medium;
  src: url(../fonts/Montserrat-Medium.ttf);
}
@font-face {
  font-family: Montserrat-SemiBold;
  src: url(../fonts/Montserrat-SemiBold.ttf);
}
@font-face {
  font-family: Montserrat-Bold;
  src: url(../fonts/Montserrat-Bold.ttf);
}
@font-face {
  font-family: PaytoneOne-Regular;
  src: url(../fonts/PaytoneOne-Regular.ttf);
}

/* -------------------------------Inter Font--------------------- */
@font-face {
  font-family: Inter-Black;
  src: url(../fonts/Inter/Inter-Black.ttf);
}
@font-face {
  font-family: Inter-Bold;
  src: url(../fonts/Inter/Inter-Bold.ttf);
}
@font-face {
  font-family: Inter-ExtraBold;
  src: url(../fonts/Inter/Inter-ExtraBold.ttf);
}
@font-face {
  font-family: Inter-ExtraLight;
  src: url(../fonts/Inter/Inter-ExtraLight.ttf);
}
@font-face {
  font-family: Inter-Light;
  src: url(../fonts/Inter/Inter-Light.ttf);
}
@font-face {
  font-family: Inter-Medium;
  src: url(../fonts/Inter/Inter-Medium.ttf);
}
@font-face {
  font-family: Inter-Regular;
  src: url(../fonts/Inter/Inter-Regular.ttf);
}
@font-face {
  font-family: Inter-SemiBold;
  src: url(../fonts/Inter/Inter-SemiBold.ttf);
}
@font-face {
  font-family: Inter-Thin;
  src: url(../fonts/Inter/Inter-Thin.ttf);
}

/* -------------------------------OSWALD  Font--------------------- */
/* -------------------------------OSWALD  Font--------------------- */

@font-face {
  font-family: Oswald-Regular;
  font-weight: 400;
  src: url(../fonts/oswald/Oswald-Regular.ttf);
}
@font-face {
  font-family: Oswald-Medium;
  font-weight: 500;
  src: url(../fonts/oswald/Oswald-Medium.ttf);
}
@font-face {
  font-family: Oswald-SemiBold;
  src: url(../fonts/oswald/Oswald-SemiBold.ttf);
}

/* -------------------------------Dosis  Font--------------------- */
/* -------------------------------Dosis  Font--------------------- */

@font-face {
  font-family: Dosis-Regular;
  font-weight: 400;
  src: url(../fonts/dosis/Dosis-Regular.ttf);
}
@font-face {
  font-family: Dosis-Medium;
  font-weight: 500;
  src: url(../fonts/dosis/Dosis-Medium.ttf);
}
@font-face {
  font-family: Dosis-SemiBold;
  font-weight: 600;
  src: url(../fonts/dosis/Dosis-SemiBold.ttf);
}

h1 {
  font-family: Oswald-SemiBold;
  color: #171717;
}
h2,
h3,
h4,
h5,
h6 {
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  color: #171717;
}
p,
span,
div,
button,
label,
table,
th,
td,
input,
a {
  font-family: Dosis-Medium;
}
p {
  color: #828282;
  font-family: Dosis-Medium;
}

::placeholder {
  font-family: Dosis-Medium;
}

button {
  font-family: Oswald-Regular;
}

.css-1bj0pzz-MuiTableCell-root {
  font-family: Dosis-SemiBold;
  font-size: 16px;
}

.css-1htgh2s-MuiTableCell-root {
  font-family: Dosis-Medium;
}

.css-m6cb7p-MuiTableCell-root {
  font-family: Dosis-SemiBold;
}

.css-1vfx5zl-MuiInputBase-input-MuiOutlinedInput-input {
  font-family: "Inter-Medium";
}
.css-1yr6kd8-MuiButtonBase-root-MuiMenuItem-root {
  font-family: Dosis-Medium;
}

.css-g404sq-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  font-family: Dosis-Medium;
}

.css-1ud382n-MuiFormLabel-root-MuiInputLabel-root {
  font-family: Dosis-Medium;
}
.css-70u1lv-MuiFormLabel-root-MuiInputLabel-root {
  font-family: Dosis-Medium;
}

.css-f4enez-MuiInputBase-input-MuiOutlinedInput-input {
  font-family: Dosis-Medium;
}

.css-ex2wus-MuiFormHelperText-root {
  font-family: Dosis-Medium;
}
