.pagination-container {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 10px;
  justify-content: end;
  flex-direction: row;
}

.pagination-container li {
  box-shadow: 0px 3px 1px -2px rgb(145 158 171 / 20%),
    0px 2px 2px 0px rgb(145 158 171 / 14%),
    0px 1px 5px 0px rgb(145 158 171 / 12%);
  background: #fff;
  margin-right: 10px;
  border-radius: 5px;
  transition: 0.2s all ease-in-out;
}

.pagination-container li:hover {
  background: #333;
  color: #fff;
}

.pagination-container li:hover a {
  color: #fff;
}
li.page.active {
  background: #26387a;
}
li.selected {
  background-color: #ff5722;
  color: #fff;
}

.pagination-container li a {
  text-decoration: none;
  padding: 5px 10px;
  display: block;
  color: #333;
}

.dashboard-card-icons {
  width: 66px;
  height: 59px;
  margin-top: 13px;
}
.ck-editor__editable {
  min-height: 300px;
}

.h2-chart {
  font-size: 17px;
}

.form-select.is-invalid {
  border-color: #d32f2f !important;
}

:root {
  --color: red;
}

.error-message {
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.bg-success,
.bg-danger {
  cursor: pointer !important;
  font-size: 12px !important;
  padding: 8px 14px !important;
  font-weight: 400 !important;
}

.analytic-details {
  margin-bottom: 24px;
  margin-top: 20px;
  text-align: center;
}

.analytic-btn {
  line-height: 1.75;
  letter-spacing: 0.02857em;
  min-width: 64px;
  color: rgb(0, 129, 255);
  font-size: 0.75rem;
  background: rgba(0, 129, 255, 0.17);
  border-radius: 2.5rem;
  padding: 0.3125rem 1.125rem;
  border: 0;
}
.analytic-btn-center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.analytic-card {
  padding: 10px;
  border: 0 !important;
  background-color: rgb(255, 255, 255) !important;
  color: rgb(52, 49, 76);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  border-radius: 8px !important;
  display: flex !important;
  padding: 1.25rem;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.card-pra {
  font-size: 14px;
}
.dashboard-card {
  border: 0 !important;
  background-color: rgb(255, 255, 255) !important;
  color: rgb(52, 49, 76);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  border-radius: 8px !important;
  padding: 1.25rem;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.04) 0px 3px 4px 0px, rgba(0, 0, 0, 0.04) 0px 1px 8px 0px !important;
}
.booking-card {
  border: 0 !important;
}

.tournament-about-paragraph p {
  color: #34314c;
  font-size: 14px;
}

ul#myTab .nav-link {
  padding: 8px 15px !important;
  text-transform: uppercase;
  margin: 0 5px;
  font-size: 14px;
  border-bottom: none;
  transition: 0s all;
  -webkit-transition: 0s all;
  -moz-transition: 0s all;
  -ms-transition: 0s all;
  -o-transition: 0s all;
}

ul#myTab .nav-link#home-link {
  color: #222b42;
  font-weight: 500;
}

ul#myTab .nav-link {
  color: #000;
  font-family: Oswald-Regular;
}
/* .profile-tabs .nav-tabs .nav-item.show .nav-link,
.nav-link.active {
  color: #26377a !important;
  border-bottom: 2px solid #26377a !important;
} */

.apexcharts-toolbar {
  display: none !important;
}
.css-1txfk2d-MuiPaper-root-MuiCard-root {
  height: 119px;
  width: 253px !important;
  min-width: 253px !important;
}

.main-img {
  position: relative;
}
.logo_img {
  position: relative;
  margin-top: -76px;
  margin-left: 15px;
  display: inline-block;
}

.logo_img span.pedit {
  position: absolute;
  left: 108px;
  top: 15px;
  width: 35px !important;
  border-radius: 50%;
  height: 35px !important;
  display: inline-block;
  box-shadow: rgb(24 24 34 / 20%) 0px 7px 29px 0px;
  background: #ffffff;
}

.logo_img span.pedit svg {
  font-size: 19px;
  color: #333;
  margin-left: 8px;
  margin-top: 6px;
}

.logo_img img {
  width: 140px !important;
  height: 140px !important;
  border: 2px solid white;
  border-radius: 53%;
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.25);
  padding: 4px;
  background-color: white;
  object-fit: cover;
}
.user_dtl {
  margin-left: 125px;
}

.ground-tab .nav-tabs .nav-link.active {
  border-radius: 5px;
  padding: 10px;
  background-color: #333;
  font-size: 14px;
  color: white !important;
  border: none !important;
  font-family: "Inter-Medium";
}

.main-card .main-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ececec;
  padding-top: 10px;
}
.main-card .main-content p {
  color: #939598;
  margin-bottom: 1px;
}
.main-card .main-content span {
  font-weight: 600;
  font-size: 14px;
}
.main-card .main-content h5 {
  font-weight: 500;
  font-size: 16px;
}

.dashboard-active {
  border: 2px solid #222b42 !important ;
}
.active-bottom {
  border-bottom: 2px solid #222b42;
}
.admin-tab {
  border-radius: 10px;
  border: 3px solid transparent;
  transition: 0.5s all ease;
  cursor: pointer;
}
.admin-tab:hover {
  border: 3px solid rgb(34, 43, 66);
  border-radius: 10x;
}
.dashboard-tab {
  border: 2px solid #222b42;
}
.admin-detail {
  font-weight: 400;
  font-size: 15px;
}

.social-content h6 {
  font-size: 10px;
}
.social-flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.faq-content-admin h1,
.faq-content-admin h2,
.faq-content-admin h3,
.faq-content-admin h4,
.faq-content-admin h5,
.faq-content-admin h6,
.faq-content-admin h1 strong,
.faq-content-admin h2 strong,
.faq-content-admin h3 strong,
.faq-content-admin h4 strong,
.faq-content-admin h5 strong,
.faq-content-admin h6 strong,
.faq-content-admin p {
  font-size: 14px;
  font-weight: 400;
  color: #171717;
  font-family: "Dosis-Medium";
}

.social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: rgba(34, 43, 66, 0.1);
}
.social-main {
  position: relative;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  background-color: #fff;
  background-clip: border-box;
  box-shadow: 0 2px 9px #b0b8d65c, 2px 1px 2px #b0b8d600;
  border-radius: 8px;
  border: 0;
  padding: 30px 13px 30px;
}
.general-content h4 {
  font-size: 20px;
  font-weight: 500;
  padding-top: 19px;
}
.general-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mui-table {
  border: 1px solid #ececec;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.14);
}
.css-1o83ncy-MuiTableCell-root {
  border-bottom: 1px solid #ececec;
}
.css-1bwc6vp-MuiTable-root .table-head {
  background: rgba(0, 0, 0, 0.05);
}

.admin-table-head {
  background: rgba(0, 0, 0, 0.05);
}

.add-amenity Button {
  background: #222b42 !important;
}
.table-img img {
  width: 35px;
  height: 29px;
}
.row-color {
  color: #828282 !important;
  font-family: "Dosis-SemiBold";
}
.table-th {
  font-size: 14px;
  color: #828282;
  padding-left: 20px !important;
}

.navbar_colorChange {
  background-color: #fff;
}
svg:not(:host).svg-inline--fa,
svg:not(:root).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box;
}

.udit_profile {
  margin-left: -33px;
  margin-top: -19px;
  width: 37px;
  opacity: 0;
  padding: -1px;
  position: absolute;
}

.add-admin {
  width: 75%;
}

.analytic-card-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: rgba(34, 43, 66, 0.1);
}

.card-box.mt-5 .card {
  margin-bottom: 30px;
  border: 0;
}

@media only screen and (max-width: 767px) {
  .add-admin {
    width: 100%;
  }

  .mui-table {
    box-shadow: none;
    border: none;
  }

  .analytics-tab {
    flex-direction: column;
    flex-wrap: wrap;
  }
  .sport-analytics-chart {
    margin-top: 10px;
  }
  .team-list {
    margin-top: 18px;
  }
}

.metismenu {
  height: calc(100vh - 175px);
  overflow: "auto";
}
.metismenu::-webkit-scrollbar {
  display: none;
}
