/* ------Common-Css------------- */

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.cursor-pointer.admin-post img.admin-post-image {
  width: 100% !important;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.7;
  font-family: "Poppins", serif;
  color: #000;
  background-color: #fff;
}

.page_wrapper {
  width: 100%;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  transition: 0.4s all;
}

a:hover {
  text-decoration: none;
}

ul,
li {
  padding: 0;
  list-style-type: none;
  margin: 0;
}
p {
  font-weight: 400;
  color: #282828;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins";
  color: #171717;
}
p,
span,
div,
button,
label,
table,
th,
td,
input,
a {
  font-family: "Poppins";
}

::placeholder {
  font-family: "Poppins";
  font-size: 14px;
}

button {
  font-family: "Poppins";
}

button:focus,
.btn.focus,
.btn:focus {
  outline: none;
  box-shadow: none;
}
.white_text p,
.white_text .section_title h2,
.white_text span,
.white_text h3 {
  color: #282828;
}

.mt-80 {
  margin-top: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mt-30 {
  margin-top: 30px;
}
.mb-30 {
  margin-bottom: 30px;
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
  .container.key-notes {
    max-width: 1340px;
  }
}

.section_title {
  text-align: center;
}

/* section heading h2 */

.section_title h2 {
  font-size: 45px;
  font-weight: 600;
  color: #282828;
  letter-spacing: 0px;
  text-transform: capitalize;
}
.section_title h2 span {
  color: #ff5722;
}
.section_title h2 span img {
  margin-left: 10px;
}

.title_badge {
  color: #fff;
  display: inline-block;
  padding: 2px 20px;
  border-radius: 100px;
  background: #ff5722;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.row_am {
  padding: 50px 0;
}
.used_app h3 {
  font-size: 18px;
  font-weight: 500;
}

.btn {
  font-weight: 600;
}

.puprple_btn {
  color: #fff;
  background: #ff5722 !important;
  font-size: 16px;
  padding: 9px 30px;
  border-radius: 25px;
  position: relative;
  transform: translate3d(-3px, -4px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition: 0.4s all;
  z-index: 2;
}

.puprple_btn:hover {
  color: #fff;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}

.btn_block {
  position: relative;
  display: inline-block;
}

.btn_bottom {
  z-index: 1;
  border: 1px solid #ff5722;
  border-radius: 100px;
  position: absolute;
  top: -8px;
  left: 8px;
  right: -12px;
  transform: translate(-0.52em, 0.52em);
  width: 100%;
  height: 100%;
}

/* white button */

.white_btn .puprple_btn {
  background-color: #fff;
  color: #000;
}

.white_btn .btn_bottom {
  border-color: #fff;
}

.white_btn:hover {
  color: #000;
}

/* slider controls */

.owl-carousel .owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 20px;
}

.owl-carousel .owl-dots button {
  display: block;
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 1px solid #ff5722;
  border-radius: 15px;
  margin: 0 5px;
}

.owl-carousel .owl-dots button.active {
  background-color: #ff5722;
}

.owl-carousel .owl-dots button span {
  background: transparent !important;
  border: 1px solid red;
  width: 15px !important;
  height: 15px !important;
  position: relative;
  margin: 0 !important;
}

.container.container-sm {
  max-width: 780px;
}

/* -------------Preloader-Css-Start-------------- */

/* Preloader */

.spin_text {
  transform-origin: 50% 50% 0;
  transform: rotate(0deg);
  -webkit-animation: spin 15s linear infinite;
  -moz-animation: spin 15s linear infinite;
  animation: spin 15s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* ------Round Animation------- */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* -----------Header-Css-Start------------------- */

/* header wraper */

header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 9999;
  transition: 0.4s all;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background-color: #fee8e6;
}

header.fix_style {
  position: fixed;
  top: 0;
  backdrop-filter: blur(5px);
  background: #fee8e6;
  padding: 0;
  transition: none;
  opacity: 0;
  pointer-events: none;
}

header.fixed {
  pointer-events: all;
  opacity: 9;
  transition: 0.4s all;
}

header.fixed .navbar {
  padding: 0;
}

.navbar {
  padding: 0;
}

.navbar-expand-lg .navbar-nav {
  align-items: center;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 26px 15px !important;
  font-weight: 500;
  transition: 0.4s all;
  color: #181a22;
  margin-left: 0 !important;
}
.navbar-expand-lg .navbar-nav .nav-item .btn_block {
  margin-left: 30px;
}

.navbar-expand-lg .navbar-nav .nav-link.dark_btn {
  color: #fff !important;
  background-color: #333;
  font-size: 16px;
  padding: 8px 32px !important;
  border-radius: 25px;
  position: relative;
  transform: translate3d(-3px, -4px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition: 0.4s all;
  z-index: 2;
  font-weight: 600;
  margin-left: 0;
}

.nav-item .btn_bottom {
  z-index: 1;
  border: 1px solid #333;
  border-radius: 100px;
  position: absolute;
  top: -8px;
  left: 8px;
  right: -12px;
  transform: translate(-0.52em, 0.52em);
  width: 100%;
  height: 100%;
}

.navbar-expand-lg .navbar-nav .nav-link.dark_btn:hover {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}

.navbar-brand img {
  width: 100%;
}
.btn_bottom.orange-border {
  border: 1px solid #ff5329;
}
a.nav-link.dark_btn.orange {
  background-color: #ff5329 !important;
}

/* navigation toggle menu */

.toggle-wrap {
  padding: 10px;
  position: relative;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.toggle-bar,
.toggle-bar::before,
.toggle-bar::after,
.toggle-wrap.active .toggle-bar,
.toggle-wrap.active .toggle-bar::before,
.toggle-wrap.active .toggle-bar::after {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.toggle-bar {
  width: 25px;
  margin: 10px 0;
  position: relative;
  border-top: 4px solid #333333;
  display: block;
}

.toggle-bar::before,
.toggle-bar::after {
  content: "";
  display: block;
  background: #333333;
  height: 4px;
  width: 30px;
  position: absolute;
  top: -12px;
  right: 0px;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -ms-transform-origin: 13%;
  -webkit-transform-origin: 13%;
  transform-origin: 13%;
}

.white_header .toggle-bar,
.white_header .toggle-bar::before,
.white_header .toggle-bar::after {
  border-top: 4px solid #333333;
}

.toggle-bar::after {
  top: 4px;
}

.toggle-wrap.active .toggle-bar {
  border-top: 6px solid transparent;
}

.toggle-wrap.active .toggle-bar::before {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toggle-wrap.active .toggle-bar::after {
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* ---------Hero-Slider-Css-Start------------------ */

/* hero slider wraper */

.banner_section {
  padding-top: 90px;
  /* padding-top: 150px; */
  padding-bottom: 150px;
  position: relative;
  background-color: #fee8e6;
  background-repeat: repeat;
}
.banner_section .container {
  position: relative;
}

.banner_section .row {
  align-items: center;
}

/* hero slider text */

.banner_section .banner_text {
  margin-top: -50px;
}

/* hero slider heading h1 */

.banner_section .banner_text h1 {
  font-size: 45px;
  color: #282828;
  letter-spacing: -0.5px;
  font-weight: 600;
  margin-bottom: 20px;
  text-transform: capitalize;
  line-height: 62px;
}
.banner_section .banner_text h1 span {
  color: #ff5722;
}
.banner_section .banner_text .type-wrap span {
  font-weight: 500;
  color: #ff5722;
}

/* hero slider button */

.banner_section .app_btn {
  display: flex;
  align-items: center;
  width: 70%;
}

ul.app_btn img {
  width: 100%;
}

/* hero slider list */

.btn_block.gray .btn_bottom {
  border: 1px solid #333;
}
.banner_section .app_btn li a:hover {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}
.banner_section .app_btn li:last-child {
  margin-left: 25px;
}

/* hero slider users */

.banner_section .used_app {
  margin: 35px 0;
}

.banner_section .used_app ul {
  display: flex;
  align-items: center;
  margin: 0 10px 20px 0;
}

.banner_section .used_app ul li:not(:first-child) {
  margin-left: -20px;
}

.banner_section .used_app ul li:not(:first-child) img {
  border: 3px solid #fff;
  border-radius: 100px;
}

.banner_section .used_app p {
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 0;
}
ul.app-button {
  display: flex;
  gap: 15px;
  padding: 0 60px 0 0;
  width: 80%;
}
ul.app-button li img {
  width: 100%;
}

/* hero slider images */

.banner_section .banner_slider {
  display: flex;
  position: relative;
}

.banner_section .banner_slider .left_icon {
  position: absolute;
  left: 70px;
  bottom: 150px;
  z-index: 9999;
}

.banner_section .banner_slider .right_icon {
  position: absolute;
  right: 80px;
  top: -10px;
}

/* hero slider mobile frame */

.banner_section .banner_slider .slider_frame {
  position: absolute;
  left: 51%;
  transform: translateX(-50%);
  z-index: 999;
  width: 300px;
}

.banner_section .banner_slider #frmae_slider {
  width: 280px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 40px;
}
.banner_section .banner_slider #frmae_slider::before {
  content: "";
  position: absolute;
  left: -5px;
  top: 5px;
  background-color: #fff;
  width: 100%;
  height: 90.5%;
  border-radius: 23px;
}
.banner_section .banner_slider::before {
  content: "";
  background-image: url("../webImages/bg.png");
  position: absolute;
  left: 50%;
  top: 46%;
  transform: translate(-50%, -50%) rotate(-8deg);
  width: 430px;
  height: 430px;
}
/* hero slider control dots */

.banner_section .owl-dots {
  margin-top: 40px !important;
}

.owl-carousel .owl-item img {
  max-width: 100%;
  width: auto !important;
}

.hero_overlay {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.vendor-banner-image img {
  /* width: 100%; */
  height: 400px;
  /* height: 550px; */
}

p.banner-vendor {
  color: rgb(255, 87, 34);
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
}

/* -----------------Task-App-Section-Css-Start------------------ */
.task_app_section {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.task_app_section .task_block {
  max-width: 1370px;
  margin: 0 auto;
  background-color: #feedeb;
  padding: 60px 150px;
  position: sticky;
  z-index: 99;
  /* border-radius: 30px; */
  overflow: hidden;
}

.task_block.top-radius {
  border-radius: 30px 30px 0 0;
}

.task_block.bottom-radius {
  border-radius: 0 0 30px 30px;
}

.task_app_section .dotes_blue {
  position: absolute;
  left: -80px;
  bottom: -100px;
}

.task_app_section .task_block .row {
  align-items: center;
}

/* Task App section wraper */
.task_app_section .task_img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Task App section images*/

.task_app_section .task_img img {
  max-width: 100%;
  border-radius: 20px;
}

.task_app_section .task_img::before {
  content: "";
  background-image: url("../webImages/bg2.png");
  position: absolute;
  left: 50%;
  top: 46%;
  transform: translate(-50%, -48%) rotate(-1deg);
  width: 430px;
  height: 430px;
  z-index: -1;
}

.task_app_section .task_img .screen_img {
  margin-left: -115px;
  margin-top: 50px;
}

.task_app_section .task_text .section_title {
  text-align: left;
}

.task_app_section .task_text .section_title h2 {
  margin-bottom: 15px;
  font-size: 35px;
}

.task_app_section .task_block .title_badge {
  background: #fff;
  color: #ff5722;
}

.task_app_section .task_text .section_title .icon {
  display: block;
  width: 80px;
  height: 80px;
  background-color: #ff5722;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0 28px 0;
}

.task_app_section .task_text .section_title .icon img {
  width: 50px;
  aspect-ratio: 1/1;
  object-fit: cover;
}
span.title_badge.title_badge2 {
  background-color: #ff5722 !important;
  color: #fff !important;
}
.custom-margin {
  margin-top: 70px;
}

/* -----------------Task-App-Section-Css-End------------------ */

/* ------------About us Section Css Start-------- */

.about_section {
  position: relative;
}

.about_section .section_title {
  margin-bottom: 70px;
}

.about_section .app_statstic {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
}

.about_section .app_statstic li:first-child,
.about_section .app_statstic li:last-child {
  width: 100%;
  text-align: center;
}

.about_section .app_statstic li .text {
  width: 160px;
  height: 160px;
  border-radius: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #ff5722;
  margin: 0 auto;
}

.about_section .app_statstic li .text p span {
  font-weight: 600;
  font-size: 35px;
  color: #ff5722;
}

.about_section .app_statstic li .text p {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 0;
  line-height: 1.2;
}

.about_section .video_block {
  position: relative;
  width: 275px;
  margin: 35px 0;
}

.about_section .video_block .thumbnil {
  max-width: 100%;
  border-radius: 20px;
}

.about_section .video_block .thumbnil img {
  border-radius: 20px;
}

.about_section .video_block .playBtn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(24, 26, 34, 0.5);
  border-radius: 100px;
  width: 100px;
  height: 100px;
}

.about_section .video_block .playBtn .play_icon {
  position: absolute;
  margin: auto;
  z-index: 99;
}

.about_section .video_block .playBtn .play_icon img {
  width: 57px;
}
.abtImg img {
  width: 100%;
  max-width: 80%;
}

/* -------------How It Work Section Css Start------------- */

.how_it_section {
  position: relative;
  padding: 30px 0;
}

.how_it_section .title_badge {
  background-color: #ff5722;
  color: #fff;
}
.how_it_inner .dotes_blue {
  position: absolute;
  top: -65%;
  left: 50%;
  transform: translateX(-50%);
}
.how_it_inner .section_title {
  margin-bottom: 50px;
  position: relative;
}
.how_it_inner .steps_block {
  text-align: center;
  position: relative;
}
.step-text h3 {
  font-size: 18px;
  color: #282828;
  font-weight: 600;
}
.step-text p {
  color: rgba(0, 0, 0, 0.7);
}
.how_it_inner .steps_block .text {
  margin-top: 40px;
}

.how_it_inner .steps_block .text .social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px 0 20px 0;
}

.how_it_inner .steps_block .text .social li a {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  text-align: center;
  line-height: 38px;
  color: #fff;
  transition: 0.4s all;
}

.how_it_inner .steps_block .text .tag_text {
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 20px 0;
}

.how_it_inner .steps_block .step {
  width: 40px;
  height: 40px;
  border: 1px dashed #9599ab;
  color: #9599ab;
  text-align: center;
  line-height: 38px;
  display: block;
  margin: 0 auto;
  border-radius: 100px;
  margin-top: 30px;
  position: relative;
}

.how_it_inner .steps_block.step_border::after {
  content: "";
  position: absolute;
  left: calc(50% + 45px);
  bottom: 20px;
  width: calc(100% - 60px);
  height: 1px;
  border-bottom: 1px dashed #9599ab;
}

.how_it_inner .text-center .btn_block {
  margin-top: 35px;
}

/* ---------- text List Flow Css Start ------------- */
section.why-choose {
  overflow-x: hidden;
}
.text_list_section .slider_block {
  background-color: #292929;
  padding: 20px 0 10px;
  transform: rotate(-1.5deg);
  width: 100%;
  overflow-x: hidden;
}

.text_list_section .down_fix {
  transform: rotate(-5deg);
  position: relative;
  bottom: -30px;
  z-index: 9;
}

.text_list_section .title_badge {
  color: #fff;
  display: inline-block;
  padding: 2px 20px;
  border-radius: 100px;
  background: #ff5722;
  margin-bottom: 10px;
}

.text_list_section .down_fix::after {
  content: "";
  position: absolute;
  right: 0;
  top: -10px;
  width: 22px;
  height: 22px;
  background-image: url("../webImages/bigstar_dark.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.text_list_section.rotet_down {
  transform: rotate(0.5deg);
}

.text_list_section .owl-stage,
.text_list_section .owl-stage-outer,
.textFlow_list .owl-stage,
.textFlow_list .owl-stage-outer {
  height: 55px;
}

.text_block {
  display: flex;
  align-items: center;
}

.text_block span {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
}
.text_block .mark_star {
  margin: 0 20px 0 50px;
}

/* ---------- text List Flow Css End ------------- */

/* =======================interface wraper Css Start======================= */

.interface_section .screen_slider {
  margin-top: 35px;
  min-height: 720px;
}
.interface_section .owl-item .screen_frame_img img {
  transform: scale(0.9);
  /* border: 2px solid #000; */
  border-radius: 20px;
  transition: 1s all;
  margin: 0 auto;
}
.interface_section .owl-item.center .screen_frame_img img {
  transform: scale(1);
  /* border: 3px solid #000; */
}
.interface_section .owl-dots {
  margin-top: 40px !important;
}
section.row_am.interface_section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
#screen_slider .owl-dots.disabled {
  display: flex;
}

/* =======================interface wraper Css End======================= */

/* =======================Our App Download Section Css Start======================= */

.download_app {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.download_app .task_block {
  /* background: var(--light-yellow); */
  background-image: url("../webImages/Section.png");
  background-repeat: repeat;
  padding: 0 80px;
  position: sticky;
  z-index: 99;
  border-radius: 30px;
  overflow: hidden;
}

.download_app .title_badge {
  background: #ff5329;
  color: #fff;
}

.download_app .frame_img {
  text-align: right;
  margin-top: 70px;
}

.download_app .frame_img img {
  max-width: 100%;
}

.download_app .frame_img::before {
  content: "";
  background-image: url("../webImages/bg.png");
  position: absolute;
  right: -9%;
  top: 46%;
  transform: translate(-35%, -19%) rotate(1deg);
  width: 427px;
  height: 600px;
  z-index: -1;
}
.download_app .dotes_blue {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -60%;
}

.download_app .task_block .row {
  align-items: center;
}

.download_app .task_block .section_title {
  text-align: left;
}

.download_app .app_btn {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.download_app .app_btn li a {
  display: block;
  color: #333;
  background-color: #fff;
  font-size: 16px;
  padding: 12px 32px;
  border-radius: 25px;
  position: relative;
  transform: translate3d(-3px, -4px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition: 0.4s all;
  z-index: 2;
  font-weight: 600;
  font-size: 14px;
}
.download_app .app_btn li a:hover {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}
.btn_bottom.btn_bottom2 {
  border: 1px solid #fff !important;
}
.download_app .app_btn li:last-child {
  margin-left: 15px;
}

.download_app .app_btn li a img {
  transition: 0.4s all;
  height: 26px;
  margin-right: 10px;
}
.section_title.section_title2 h2,
.section_title.section_title2 p {
  color: #fff;
}

/* =======================Faq's Section Css Start======================= */

section.Faq {
  padding: 40px 0;
}
#main {
  margin: 60px 0 0;
}
#main #faq .card {
  margin-bottom: 26px;
  border: 0;
}

#main #faq .card .card-header {
  border: 0;
  -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  border-radius: 2px;
  padding: 0;
}
#main #faq .card .card-header .btn-header-link {
  display: block;
  text-align: left;
  color: #222;
  padding: 20px;
  background: #ffffff;
  border: 1px solid rgba(32, 34, 91, 0.098);
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 0;
  width: 100%;
}
#main #faq .card .card-header .btn-header-link:after {
  content: "\f106";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  float: right;
  background: rgba(255, 87, 34, 0.1);
  width: 30px;
  height: 30px;
  border-radius: 100%;
  color: #ff5722;
  text-align: center;
  line-height: 30px;
}
#main #faq .card .card-header .btn-header-link.collapsed {
  color: #000;
  background: #ffffff;
  border: 1px solid rgba(32, 34, 91, 0.098);
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 10px;
  width: 100%;
}

#main #faq .card .card-header .btn-header-link.collapsed:after {
  content: "\f105";
}

#main #faq .card .collapsing {
  border: 1px solid rgba(32, 34, 91, 0.098);
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 0;
  line-height: 30px;
  background: #ffffff;
}

#main #faq .card .collapse {
  border: 0;
}

#main #faq .card .collapse.show {
  line-height: 30px;
  background: #ffffff;
  border: 1px solid rgba(32, 34, 91, 0.098);
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 0;
}

#main iframe {
  width: 100% !important;
  height: 550px !important;
}

#main .card-body h1,
#main .card-body h2,
#main .card-body h3,
#main .card-body h4,
#main .card-body h5,
#main .card-body h6 {
  font-size: 16px;
}

#video-container iframe {
  width: 100%;
}

/* =======================Faq's Section Css Start======================= */

/* =======================Footer Css Start======================= */

.footer-logo img {
  width: 100%;
  max-width: 70%;
  margin: 8px 0;
}
.widgets p {
  color: rgba(255, 255, 255, 0.7);
  margin-top: 8px;
  font-size: 15px;
  line-height: 29px;
  width: 100%;
  padding-right: 15px;
}
ul.social-media-footer li {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: #fff;
  color: #fff;
  font-size: 16px;
  text-align: center;
  border-radius: 100%;
  line-height: 40px;
  margin-right: 6px;
}
ul.social-media-footer li a svg {
  color: #ff5329;
  font-size: 18px;
}
footer.xs-footer-sec {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 60px;
}
h3.widget-title {
  color: #fff;
  font-size: 20px;
  margin-bottom: 24px;
  position: relative;
  font-weight: 600;
}
.widgets ul li a {
  display: block;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  margin-bottom: 10px;
}
.widgets.pdleft30.cmpny {
  padding-left: 32px;
}
.pdleft40 {
  padding-left: 40px;
}
ul.footer-contact-list li {
  color: #000;
  font-size: 16px;
  margin-bottom: 12px;
  padding-left: 26px;
  position: relative;
}
ul.footer-contact-list.social li a {
  margin-bottom: 4px;
}
.widgets.cmpny ul li a {
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  color: #ffffffb3;
}
ul.footer-contact-list svg {
  margin-right: 9px;
  font-size: 18px;
  color: #ff5722;
  position: absolute;
  left: 0;
}
p.Cricbooks {
  margin-bottom: 0;
  font-size: 14px;
  color: #afafaf;
  border-top: 0.3px solid #afafaf6e;
  padding: 18px 0;
  margin-top: 44px;
}

/* =======================Footer Css Start======================= */

/* =======================Testimonials_section Css Start======================= */

section.Testimonials_section {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0;
}
.owl-carousel {
  display: block;
  width: 100%;
  z-index: 1;
}
.partner {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  padding: 44px 32px 60px;
}

.user-name h6 {
  font-size: 20px;
  margin-bottom: 0;
}
.user-name span {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.7);
}

.partner-imag p {
  margin-bottom: 32px;
  font-size: 15px;
}
.partner-imag img {
  margin: 0 auto;
}

/* =======================Testimonials_section Css End======================= */

/* =======================Vendor Page Css Start======================= */
/* =======================Vendor Page Css Start======================= */
/* =======================Vendor Page Css Start======================= */

.section_title.why-choose-us {
  margin-bottom: 70px;
}

.choose-us-left {
  border: 1px solid #ff5722b2;
  padding: 15px;
  border-radius: 20px;
  box-shadow: 0px 10px 19px -1px rgba(254, 237, 235, 0.8);
  margin-bottom: 25px;
}

.choose-us-card {
  display: flex;
  gap: 15px;
  align-items: center;
}

.choose-us-card-text h5 {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}

.choose-us-card-text p {
  color: #000000b2;
  font-size: 14px;
}

.choose-us-card-icon-circle {
  width: 70px;
  height: 70px;
  background: #fee8e6;
  border-radius: 50%;
  text-align: center;
  align-content: center;
}

.choose-us-card-icon-circle img {
  width: 44px;
}

.choose-us-right img {
  width: 100%;
}

.choose-us-right {
  position: relative;
}

.supoort-card {
  background-color: #ff5722;
  width: 49%;
  border-radius: 30px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.supoort-card p {
  font-size: 14px;
  color: #fff;
  margin-bottom: 0;
}

.supoort-card-icon {
  background-color: #fff;
  padding: 9px 16px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.supoort-card-icon svg {
  transform: rotate(45deg);
  font-size: 20px;
}

.supoort-card-video {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #ff5722;
  border-radius: 30px;
  padding: 15px;
  width: 31%;
  height: 165px;
  text-align: center;
  align-content: center;
  cursor: pointer;
}

.supoort-card-video img {
  width: 85%;
}

section.what-we-provide {
  border: 1px solid #ff5722;
  box-shadow: 0px 10px 19px -1px rgba(254, 237, 235, 0.8);
  border-radius: 25px;
  padding: 50px;
}

.what-we-provide-img img {
  width: 100%;
}

.what-we-provide-text {
  padding: 0 40px;
}

.what-we-provide-icon {
  width: 80px;
  height: 80px;
  background-color: #ff5722;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 25px;
}

.what-we-provide-text h1 {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 30px;
}

.what-we-provide-text h1 span {
  color: #ff5722;
}
.what-we-provide-text p {
  color: #828282;
  line-height: 28px;
  font-size: 16px;
}

.what-we-provide-icon img {
  width: 50px;
}

.what-we-provide-img {
  margin-bottom: 80px;
}

.what-we-provide-img.end {
  margin-bottom: 0;
}

section.key-notes-section {
  margin: 80px 0;
  background-image: url("../webImages/key-notes.svg");
  padding: 50px 70px;
  border-radius: 30px;
}

section.key-notes-section button.nav-link {
  background-color: #5c5c5c;
  margin-bottom: 20px;
  color: #fff;
  padding: 13px;
  font-size: 16px;
  font-weight: 500;
  width: 250px;
}

section.key-notes-section .nav-link.active {
  background-color: #fff !important;
  position: relative;
  color: #000 !important;
}
section.key-notes-section .nav-link.active::after {
  content: "";
  position: absolute;
  right: -4%;
  top: 27%;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 13px solid #fff;
}

.tab-content-vendor img {
  width: 400px;
  /* width: 100%; */
}

.tab-content-vendor h2 {
  font-size: 22px;
  color: #fff;
  line-height: 32px;
}

.tab-content-vendor p {
  color: #ffffffe5;
  font-size: 14px;
}

section.easy-steps-section {
  margin-bottom: 30px;
}

.easy-steps-card {
  border: 1px solid #ff5722;
  border-radius: 20px;
  padding: 20px;
  position: relative;
}

.easy-steps-card-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.easy-steps-card-top .icon {
  width: 70px;
  height: 70px;
  background-color: #fee8e6;
  border-radius: 50%;
  text-align: center;
  align-content: center;
}

.easy-steps-card-top img {
  width: 40px;
}

.easy-steps-card-top h2 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #282828;
  margin-bottom: 0;
}

.easy-steps-card p {
  color: #828282;
  margin-bottom: 0;
}

.easy-steps-card {
  border: 1px solid #ff5722;
  border-radius: 20px;
  padding: 30px 30px 30px;
  position: relative;
}

.easy-steps-card .step {
  bottom: 0;
  height: 80px;
  position: absolute;
  right: 0;
  width: 75px;
}

.easy-steps-card .step::before {
  background: url("../webImages/step-card-shape.png");
  bottom: -1px;
  content: "";
  height: 84px;
  left: 0;
  position: absolute;
  width: 76px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 9;
}

.easy-steps-card .step .step-box {
  align-content: center;
  background: #ff5329;
  border-radius: 13px;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  font-size: 22px;
  height: 50px;
  justify-content: center;
  margin: 30px;
  width: 50px;
  z-index: 9;
  position: relative;
}

.easy-steps-bottom-img {
  margin-top: 70px;
}

.easy-steps-bottom-img img {
  width: 100%;
}

.about-banner {
  background-image: url("../webImages/about-page-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 53vh;
  padding: 100px 0;
  align-content: center;
}

.about-banner .section_title h2 {
  margin-bottom: 20px;
}

.about-banner .title_badge {
  margin-bottom: 15px !important;
}

.company-overview-img img {
  width: 100%;
}

.company-overview-text {
  padding-left: 25px;
}

.company-overview-text .section_title p {
  color: #828282;
  font-size: 16px;
  text-align: left;
}

p.section_title-heading {
  color: #333 !important;
}

.company-overview-text .section_title {
  text-align: left;
}
.company-overview-text .section_title h2 {
  font-size: 30px;
  line-height: 40px;
  margin-top: 10px;
}

.company-overview-text .section_title ul li {
  display: flex;
  gap: 10px;
}

.company-overview-text .section_title ul li svg {
  color: #ff5329;
  font-size: 25px;
  margin-top: 2px;
}

.company-overview-text button {
  background-color: #ff5329;
  border: none;
  padding: 10px 30px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  margin-top: 10px;
  line-height: 25px;
}

.about-cards {
  border: 1px solid #ff5722;
  box-shadow: 0px 3.05089px 20.3393px rgba(0, 0, 0, 0.12);
  border-radius: 22.8817px;
  padding: 20px;
  text-align: center;
  display: flex;
  gap: 18px;
  flex-direction: column;
  align-items: center;
  height: 385px;
}

.about-cards-icon {
  width: 70px;
  height: 70px;
  border-radius: 8px;
  background-color: #ff5329;
  padding: 10px;
}

.about-cards-icon img {
  width: 100%;
}

.about-cards h5 {
  font-size: 20px;
  line-height: 23px;
  font-weight: 600;
  margin-bottom: 0;
}

.about-cards p {
  margin-bottom: 0;
  color: #707070;
}

.company-overview-text.new2 {
  padding-right: 25px;
}

.contact-us-cards {
  background: #ffffff;
  box-shadow: 0px 3.05089px 20.3393px rgba(0, 0, 0, 0.12);
  padding: 24px;
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 290px;
}

.contact-us-cards .icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #ff5329;
  text-align: center;
  align-content: center;
}

.contact-us-cards .icon svg {
  font-size: 30px;
  color: #fff;
}
.contact-us-cards h5 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}
.contact-us-cards p {
  color: #828282;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 0;
}

.contact-form-card {
  border: 1px solid #ff5722;
  box-shadow: 0px 10px 19px -1px rgba(254, 237, 235, 0.8);
  border-radius: 20px;
  padding: 30px;
  text-align: center;
}

.contact-form-card .form-control {
  height: 60px;
  padding: 15px 20px;
  font-size: 16px;
  margin-bottom: 20px;
  background: rgba(255, 87, 34, 0.05);
  border: 1px solid #ff5722;
  border-radius: 12px;
}

.contact-form-card button {
  width: 100%;
  display: block;
  border: none;
  padding: 12px 0;
  color: #fff;
  font-weight: 500;
  background-color: #ff5329;
  border-radius: 10px;
}

.contact-form-card h2 {
  font-size: 30px;
  font-weight: 600;
}
.contact-form-card p {
  color: #828282;
  font-size: 16px;
}

.contact-form-card textarea {
  height: 150px !important;
}

.contact-form-image img {
  width: 93%;
}

.address-map iframe {
  width: 100%;
  height: 550px;
}

.login-section {
  background-image: url("../webImages/about-page-bg.png");
  /* background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  padding: 50px 0; */

  min-height: 100vh;
  background-size: contain;
  padding: 50px 0;
}

.login-top-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-top-content p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 18px;
}

.login-top-content img {
  width: 40%;
}

.login-container {
  background-image: url("../webImages/login-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 35px;
  margin-top: 40px;
  padding: 50px;
  padding-bottom: 0;
  overflow: hidden;
}

.login-container-text h1 {
  font-size: 35px;
  font-weight: 600;
  line-height: 60px;
  color: #fff;
  text-transform: capitalize;
}

.login-container-text p {
  color: #fff;
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
}

.login-container-text img {
  width: 75%;
}

.login-container-form {
  background-color: #fff;
  padding: 40px;
  border-radius: 20px;
  text-align: center;
}

.login-container-form span.welcome {
  text-align: center;
  background: #ff5722;
  padding: 10px 28px;
  border-radius: 40px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 29px;
  text-transform: uppercase;
}

.login-container-form h1 {
  font-weight: 600;
  font-size: 22px;
  margin: 18px 0 10px;
}
.login-container-form p {
  color: #828282;
  font-size: 14px;
}

.login-container-form input#outlined-start-adornment {
  border-left: 1px solid #d6d6d6;
  padding-left: 12px;
}

.term-condition p {
  color: #333333;
  margin-top: 4px;
  text-align: left;
  font-size: 14px;
}

form.login-form button.Login-button {
  background-color: #ff5722;
  border: none;
  width: 100%;
  padding: 10px;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
}

form.login-form p.error-message {
  color: #d32f2f;
  font-size: 13px !important;
}

.login-container-form ul.social-icon li {
  background-color: #ff5722;
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  text-align: center;
  line-height: 46px;
  font-size: 19px;
  margin: 0 4px;
}

.login-container-form ul.social-icon li a svg {
  color: #fff;
}

img.web-profile-img-form {
  position: relative;
  top: 61px;
  width: 85%;
}

.login-container.web-profile {
  padding-bottom: 50px;
}

/* =======================Vendor Page Css End======================= */

@media screen and (max-width: 767px) {
  section.banner_section.white_text {
    text-align: center;
  }
  .banner_section .banner_text h1 {
    font-size: 26px;
    margin-bottom: 15px;
    line-height: 35px;
  }
  .banner_section .used_app ul {
    justify-content: center;
  }
  ul.app_btn {
    justify-content: center;
    width: 100% !important;
  }
  .banner_section .app_btn li a img {
    height: auto;
  }
  .banner_section .app_btn li a {
    padding: 0;
    font-size: 9px;
  }
  .banner_section .banner_slider {
    margin-top: 50px;
  }
  .banner_section .banner_slider::before {
    width: 300px;
    height: 300px;
  }
  .banner_section .banner_slider .right_icon {
    right: 0px;
  }
  .banner_section {
    padding-bottom: 60px;
  }
  .section_title h2 {
    font-size: 24px;
  }
  .step-image img {
    width: 100%;
  }
  .how_it_inner .steps_block.step_border::after {
    display: none;
  }
  .how_it_inner .steps_block .step {
    margin-top: 0px;
  }
  .steps_block.step_border {
    margin-bottom: 20px;
  }
  .task_app_section .task_block {
    position: relative;
    top: 0 !important;
    margin-bottom: 30px;
    padding: 36px 20px;
    /* border-radius: 30px; */
  }

  .task_app_section .task_img {
    margin-bottom: 20px;
  }
  .task_app_section .task_img::before {
    width: 428px;
    height: 421px;
  }
  .task_app_section .task_text .section_title {
    text-align: center;
  }
  .task_app_section .task_text .section_title .icon {
    margin: 18px auto 15px auto;
  }
  .task_app_section .task_text .section_title h2 {
    font-size: 22px;
  }
  .custom-margin {
    margin-top: 50px;
  }
  .row_am {
    padding: 30px 0;
  }
  .section_title h2 br {
    display: none;
  }
  .about_section .section_title {
    margin-bottom: 50px;
  }
  .about_section .app_statstic li .text {
    width: 125px;
    height: 125px;
  }
  .about_section .row div:last-child {
    text-align: center;
  }
  .about_section .abtImg {
    margin: 20px 0;
  }
  .text_list_section .down_fix {
    bottom: -15px;
  }
  .text_block span {
    font-size: 30px;
  }
  .text_list_section .owl-stage,
  .text_list_section .owl-stage-outer,
  .textFlow_list .owl-stage,
  .textFlow_list .owl-stage-outer {
    height: 35px;
  }
  .download_app .task_block {
    padding: 28px 15px 0 20px;
  }
  .download_app .task_block .section_title {
    text-align: center;
  }
  .title_badge {
    font-size: 14px;
    margin-bottom: 12px !important;
  }
  .widgets.pdleft30.cmpny {
    padding-left: 0;
  }
  .pdleft40 {
    padding-left: 0;
  }
  .widgets {
    margin-bottom: 24px;
  }

  p.Cricbooks {
    font-size: 12px;
    padding: 16px 0;
    margin-top: 9px;
  }

  footer.xs-footer-sec {
    padding-top: 35px;
  }

  #main #faq .card .card-header .btn-header-link {
    padding: 18px 11px;
    font-size: 12px;
  }

  #main #faq .card .card-header .btn-header-link:after {
    position: relative;
    bottom: 5px;
  }

  #main {
    margin: 40px 0 0;
  }

  .card-body {
    font-size: 14px;
    padding: 14px;
  }

  ul.app-button {
    gap: 10px;
    padding: 0 0 0 0;
    width: 100%;
  }

  header {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .navbar {
    padding: 0 10px;
  }

  .navbar-brand img {
    width: 180px;
  }

  .navbar-toggler {
    padding-right: 0;
    line-height: 1.7;
  }

  .toggle-wrap {
    padding-right: 0;
    padding: 0px;
  }

  .navbar-toggler-icon {
    background-image: none !important;
  }

  .navbar-expand-lg .navbar-nav {
    padding: 20px 15px;
    background-color: #fff;
    border-radius: 15px;
    /* box-shadow: 0 4px 10px #e9e9e9; */
    margin-bottom: 30px;
    border: 1px solid #e2dcd1;
  }

  .navbar-expand-lg .navbar-nav {
    align-items: initial;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 10px 6px !important;
  }

  .navbar-expand-lg .navbar-nav .nav-item .btn_block {
    margin-left: 0;
    margin-top: 16px;
    width: 100%;
    text-align: center;
  }

  ul.navbar-nav.ml-auto .btn_block {
    display: block;
    text-align: center;
  }

  .partner {
    padding: 50px 10px 74px;
    background-position: 75% 100%;
  }

  .partner-imag ul {
    padding: 18px 0 8px;
  }

  .partner-imag p {
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 26px;
  }

  section.Testimonials_section {
    padding: 20px 0;
  }

  header.fix_style.fixed {
    padding: 10px 0;
  }

  .about_section .video_block {
    width: 100%;
  }

  .interface_section .screen_slider {
    min-height: auto;
  }

  .download_app .frame_img::before {
    left: 33%;
    width: 367px;
    height: 430px;
    top: 55%;
  }

  section.Faq {
    padding: 26px 0;
  }

  .vendor-banner-image img {
    height: 100%;
    width: 100%;
  }

  section.easy-steps-section {
    margin-top: 30px;
  }

  .easy-steps-bottom-img {
    margin-top: 30px;
  }

  section.why-choose.mb-80 {
    margin-bottom: 30px;
  }

  .section_title.why-choose-us {
    margin-bottom: 30px;
  }

  section.key-notes-section {
    padding: 50px 30px;
  }

  section.key-notes-section button.nav-link {
    width: 100%;
  }
  .what-we-provide-text {
    padding: 0;
  }
  .what-we-provide-text h1 br {
    display: none;
  }

  .what-we-provide-text h1 {
    font-size: 24px;
    text-align: center;
  }

  section.what-we-provide {
    padding: 50px 20px;
  }

  .what-we-provide-icon {
    margin: 18px auto 15px auto;
  }

  .supoort-card {
    width: 50%;
    padding: 7px 10px;
    top: 20px;
  }

  .supoort-card p {
    font-size: 11px;
    font-weight: 500;
  }

  .supoort-card-icon {
    padding: 6px 11px;
    width: 40px;
    height: 40px;
  }

  .supoort-card-icon svg {
    font-size: 17px;
  }

  .supoort-card-video {
    border-radius: 15px;
    height: 108px;
  }

  .supoort-card-video img {
    width: 100%;
  }

  .about-banner {
    padding: 30px 0;
  }

  .company-overview-text .section_title h2 {
    font-size: 22px;
    line-height: 31px;
  }

  .company-overview-text {
    padding-left: 0;
  }

  .company-overview-text .section_title {
    text-align: left;
  }

  section.company-overview-section.mt-80.mb-80 {
    margin-bottom: 30px;
  }

  section.about-card-section.mb-80 {
    margin-bottom: 30px;
  }

  section.company-overview-section.mt-80 {
    margin-top: 30px;
  }

  .company-overview-text.new2 {
    padding-right: 0;
  }

  section.contact-us {
    padding: 0 !important;
  }

  .contact-us-cards-section.mt-80.mb-80 {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .contact-form-card h2 {
    font-size: 24px;
  }

  .contact-form-card p {
    font-size: 14px;
  }

  .contact-form-card {
    padding: 30px 15px;
  }

  section.row_am.download_app.mt-30 {
    padding-top: 0;
  }

  .login-section {
    padding: 30px 0;
  }

  .login-container {
    margin: 0 10px;
    padding: 10px;
  }

  .login-container .row.align-items-center {
    flex-direction: column-reverse;
  }

  .login-container-text {
    margin-top: 20px;
  }

  .login-container-form {
    padding: 20px;
  }

  .login-container-text h1 {
    font-size: 24px;
    line-height: 35px;
  }

  .login-top-content img {
    width: 50%;
  }

  .otp {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-top-content p {
    font-size: 15px;
  }

  .otp .inputStyle input {
    width: 35px !important;
    height: 35px !important;
    margin: 0px 7px 20px !important;
  }

  .row.reverse-on-mobile {
    flex-direction: column-reverse;
  }

  .login-top-content {
    margin-bottom: 30px;
  }

  .what-we-provide-img {
    margin-bottom: 40px;
  }

  .choose-us-card {
    flex-wrap: wrap;
  }
  .about-cards {
    height: auto;
  }

  .tab-content-vendor img {
    width: 100%;
  }
}
